import React from 'react'
import { Link } from 'gatsby'

import Hero from "../components/hero"
import Layout from "../components/layout"
import MaxWidth from "../components/maxWidth.js"
import SEO from "../components/seo"

const ForBusinessPage = () => (
    <>
        <Hero>
            <h1>About the Referral Process</h1>
        </Hero>
     <Layout>
        <SEO title="About the Referral Process" keywords={[`EAP Referral`, `Employee Assistance`, `Employee Referral`]} />
        <MaxWidth>
            <p>There are essentially two ways that a person may enter the EAP: self referral or management referral.</p>

            <h2>Self Referral</h2>
            <p>An employee may call Corporate EAP Resources to speak to an EAP counselor. An appointment will be scheduled at a time convenient for the employee. All scheduled appointments, as well as what is discussed in the interview, are handled in strict confidence.</p>
            <p style={{marginBottom: '.8rem'}}>If you are an employee planning to call Corporate EAP Resources, please call our office and ask to speak to the EAP Counselor. If, for any reason, a complication arises when you call, ask to speak to the EAP director, and he or she can direct you appropriately. Day and evening appointments are available.</p>
            <Link to='/contact-us' className='btn primary' style={{marginBottom: '2rem'}}>Contact Us</Link>

            <h2>Management Referral</h2>
            <p>Managers/Supervisors may recommend to an employee that she/he seek help from the EAP when</p>
                <ul>
                    <li>There is a noticeable decline in the employee’s work performance</li>
                    <li>There are specific on-the-job incidents or behavior which indicate the presence of a personal problem</li>
                </ul>
            <p style={{marginBottom: '.8rem'}}>The focus is on the job situation and not on the nature of the personal problem. No change has been made in the usual supervisory procedures for handling performance problems. Both management and supervisors, however, are encouraged to suggest a referral to the EAP whenever the employee’s work performance is declining.</p>
            <Link to='/contact-us' className='btn primary' style={{marginBottom: '2rem'}}>Contact Us</Link>

            <h3>A Note on Confidentiality</h3>
            <p>The decision to seek confidential assistance must be made solely by the employee. No employee should be forced to use the program. If the employee chooses to accept a referral to the Employee Assistance Program, he/she is to be assured that:</p>
            <ul>
                <li>No report of contact with the EAP will be maintained in his/her personnel file.</li>
                <li>Whatever is discussed in the EAP office will remain confidential (unless a request for a release of information is made by the employee).</li>
            </ul>
            <Link to='/privacy' className='btn secondary' style={{marginLeft: '0px'}}>Learn About Privacy</Link>
        </MaxWidth>
    </Layout>
	 </>
)

export default ForBusinessPage